import { useState, useEffect, useCallback } from 'react';

/**
 * A custom React hook that manages a repeating interval for executing a callback function.
 *
 * This hook sets up an interval that calls the provided callback function
 * at a specified delay (in milliseconds). It also provides a way to clear
 * the interval programmatically.
 *
 * @param {() => void} callback - The function to be executed at each interval.
 * @param {number} delay - The time interval (in milliseconds) between each callback execution.
 * @param {boolean} start - A flag that indicates whether to start or stop the interval.
 *
 * @returns {() => void} A function to manually clear the interval.
 */
const useInterval = (callback: () => void, delay: number, start: boolean) => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (start) {
      const id = setInterval(callback, delay);
      setIntervalId(id);
      return () => clearInterval(id);
    }

    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [start]);

  const clear = useCallback(() => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [intervalId]);

  return clear;
};

export default useInterval;
