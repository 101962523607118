import React, { useEffect, useState } from 'react';
import { Coverage, Overlay } from '@picsart/ds-components/Overlay';
import { Text, TypographyHorizontalAlign } from '@picsart/ds-components/Text';
import { View } from '@picsart/ds-components/View';
import { Progress, ProgressVariants } from '@picsart/ds-components/Progress';
import { Typography } from '@picsart/ds-foundation/typography';
import { FontWeights } from '@picsart/ds-foundation/fonts';
import { Spacings } from '@picsart/ds-foundation/spacings';
import { Texts } from '@picsart/ds-foundation/colors';
import useInterval from 'hooks/useInterval';
import { useStyles } from './StylePackProgressStyles';

interface Props {
  styleCreatedDate?: string;
}

const StylePackProgress = ({ styleCreatedDate }: Props) => {
  const [progressPercent, setProgressPercent] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    const createdDate = new Date(styleCreatedDate).getTime();
    const now = Date.now();
    const elapsedMilliseconds = now - createdDate;

    // Define the total time to reach 98% (5 minutes in milliseconds)
    const totalMilliseconds = 5 * 60 * 1000;

    // Calculate the initial progress percentage
    const newProgress = Math.min((elapsedMilliseconds / totalMilliseconds) * 98, 98);
    setProgressPercent(newProgress);
  }, [styleCreatedDate]);

  const progress = () => {
    const increment = 98 / (5 * 60); // Increment per second (98% over 5 minutes)
    setProgressPercent((prev) => Math.min(prev + increment, 98));
  };

  const clear = useInterval(progress, 1000, true);

  useEffect(() => {
    if (progressPercent >= 98) {
      clear();
    }
  }, [progressPercent, clear]);

  useEffect(() => {
    return () => {
      clear();
      setProgressPercent(0);
    };
  }, []);

  return (
    <Overlay coverage={Coverage.Local} className={classes.root}>
      <View className={classes.contentRoot}>
        <View className={classes.contentBox}>
          <Progress variant={ProgressVariants.Linear} percent={progressPercent} />
          <Text className={classes.title}>Not long to wait now!</Text>
          <Text className={classes.description}>Your avatars will be ready in just a few minutes.</Text>
        </View>
      </View>
    </Overlay>
  );
};

export default StylePackProgress;
