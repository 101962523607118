import { GenerationFlowTypesEnum, ImageFaceCheckStepsEnum } from 'constants/constants';
import { Button, ButtonSkinTertiary } from '@picsart/ds-components/Button';
import { Text, TypographyHorizontalAlign } from '@picsart/ds-components/Text';
import { ImageFaceCheckStepsOptions } from 'constants/constants';
import { View } from '@picsart/ds-components/View';
import { useStyles } from './UploadPopUpStyles';
import { FontWeights } from '@picsart/ds-foundation/fonts';
import { Statuses } from '@picsart/ds-foundation/colors/statuses';
import { Typography } from '@picsart/ds-foundation/typography';
import { useRef } from 'react';
import { IReduxState } from 'types';
import { getDiscordSKUs } from 'store/slices/main/selectors';
import { useSelector } from 'react-redux';
import { PriceUtils } from '@discord/embedded-app-sdk';

interface Props {
  status: ImageFaceCheckStepsEnum;
  handleImageReplace: (file: File) => void;
  handleConfirm: () => void;
  isErrorVisible: boolean;
  isLoadingVisible: boolean;
  generationFlow: GenerationFlowTypesEnum;
}

const uploadActionsSelector = (state: IReduxState) => ({
  skus: getDiscordSKUs(state),
});

const UploadActions = ({ status, handleImageReplace, isErrorVisible, isLoadingVisible, handleConfirm, generationFlow }: Props) => {
  const classes = useStyles();
  const replaceButtonRef = useRef<HTMLInputElement>(null);

  const { skus } = useSelector(uploadActionsSelector);

  const handleInputClick = () => {
    replaceButtonRef.current.value = null;
    replaceButtonRef.current.click();
  };

  const getCreateButtonTitle = () => {
    const isFreeGenerationFlow = generationFlow === GenerationFlowTypesEnum.FREE_AVATAR_GENERATION;
    return isFreeGenerationFlow ? 'Create avatar' : `Create avatars for ${PriceUtils.formatPrice(skus[0].price)}`;
  };

  return (
    <View className={classes.actions}>
      {status !== ImageFaceCheckStepsEnum.NULL && (
        <Text size={Typography.t4} weight={FontWeights.Medium} align={TypographyHorizontalAlign.Center} color={ImageFaceCheckStepsOptions[status].textColor}>
          {ImageFaceCheckStepsOptions[status].description}
        </Text>
      )}
      {isErrorVisible && (
        <Text size={Typography.t4} weight={FontWeights.Medium} align={TypographyHorizontalAlign.Center} color={Statuses.error.base.default}>
          Something went wrong. Please try again.
        </Text>
      )}
      <Button
        skin={ButtonSkinTertiary}
        className={classes.actionButton}
        onClick={handleInputClick}
        isDisabled={(status !== ImageFaceCheckStepsEnum.NULL && status !== ImageFaceCheckStepsEnum.NOT_VALID) || isLoadingVisible}
      >
        Replace Image
        <input hidden ref={replaceButtonRef} accept='image/png, image/jpeg, image/jpg' type='file' onChange={(e) => handleImageReplace(e.target.files[0])} />
      </Button>
      <Button className={classes.actionButton} isDisabled={status !== ImageFaceCheckStepsEnum.NULL || isLoadingVisible} onClick={() => handleConfirm()}>
        {getCreateButtonTitle()}
      </Button>
    </View>
  );
};

export default UploadActions;
