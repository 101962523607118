import { FontWeights } from '@picsart/ds-foundation/fonts';
import { Typography } from '@picsart/ds-foundation/typography';
import { Spacings } from '@picsart/ds-foundation/spacings';
import { createUseStyles } from 'react-jss';
import { TypographyHorizontalAlign } from '@picsart/ds-components/Text';

export const useStyles = createUseStyles({
  root: {
    width: '100% !important',
    height: '100% !important',
    position: 'absolute',
    left: Spacings.s0,
    bottom: Spacings.s0,
    right: Spacings.s0,
    top: Spacings.s0,
  },
  contentRoot: {
    width: '100% !important',
    height: '100% !important',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentBox: {
    gap: Spacings.s24,
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '288px',
  },
  title: {
    fontWeight: FontWeights.SemiBold,
    fontSize: Typography.t5.fontSize,
    lineHeight: Typography.t5.lineHeight,
    textAlign: TypographyHorizontalAlign.Center,
  },
  description: {
    fontWeight: FontWeights.Medium,
    fontSize: Typography.t4.fontSize,
    lineHeight: Typography.t4.lineHeight,
    textAlign: TypographyHorizontalAlign.Center,
  },
});
