import { View } from '@picsart/ds-components/View';
import { Text, TypographyHorizontalAlign } from '@picsart/ds-components/Text';
import { FontWeights } from '@picsart/ds-foundation/fonts';
import { Typography } from '@picsart/ds-foundation/typography';
import { Skeleton } from '@picsart/ds-components/Skeleton';
import { Backgrounds } from '@picsart/ds-foundation/colors/backgrounds';
import { Image } from '@picsart/ds-components/Image';
import { Button, ButtonSizeSM, ButtonSkinSecondary } from '@picsart/ds-components/Button';
import { useStyles } from './GeneratedImageStyles';
import { IHistoryImage } from 'types';
import { getCDNURL } from 'utils/getCDNUrl';
import useDownload from 'hooks/useDownload';

interface Props {
  image: IHistoryImage;
  isLoading: boolean;
  isFailed: boolean;
  index: number;
  handleImageClick: (index: number) => void;
}

const GeneratedImage = ({ index, image, isLoading, isFailed, handleImageClick }: Props) => {
  const classes = useStyles();
  const { download } = useDownload();

  const handleDownload = () => {
    if (image) {
      download(image.url);
    }
  };

  return isFailed ? (
    <View width='100%' position='relative' className={classes.skeletonWrapper} backgroundColor={Backgrounds.positive} />
  ) : isLoading ? (
    <View width='100%' position='relative' className={classes.skeletonWrapper}>
      <Skeleton width={1000} height={1000} />
    </View>
  ) : (
    <View flexDirection='column'>
      <View className={classes.packGridItem} key={image?._id} flex={1}>
        <Image
          key={image?._id}
          width={'100%'}
          height={'auto'}
          className={classes.generatedImage}
          src={image?.url ? `${getCDNURL(image)}?type=webp&to=crop&r=512` : null}
          onClick={() => handleImageClick(index)}
        />
        <View
          className={classes.downloadBox}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            handleDownload();
          }}
        >
          <Text weight={FontWeights.SemiBold} size={Typography.t5} align={TypographyHorizontalAlign.Center} color={Backgrounds.positive}>
            Download
          </Text>
        </View>
      </View>
      <Button className={classes.downloadButton} size={ButtonSizeSM} skin={ButtonSkinSecondary} isDisabled={isLoading} onClick={handleDownload}>
        Download
      </Button>
    </View>
  );
};

export default GeneratedImage;
