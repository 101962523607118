import { IStyleAttributes } from 'types';
import { useStyles } from './StylesSelectionStyles';
import { View } from '@picsart/ds-components/View';
import { Text } from '@picsart/ds-components/Text';
import { ImageFrame } from '@picsart/ds-components/ImageFrame';
import { Image } from '@picsart/ds-components/Image';
import { IconTickLarge } from '@picsart/ds-foundation/Icons/IconTickLarge';
import { Texts } from '@picsart/ds-foundation/colors/texts';
import { DEVICE_SCREEN_MAXIMUM_SIZES, DISCORD_ACTIVITY_URL_MAPPINGS } from 'constants/constants';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { ITypography, Typography } from '@picsart/ds-foundation/typography';

interface Props {
  style: IStyleAttributes;
  isSelected: boolean;
  handleSelect: () => void;
}

const Style = ({ style, isSelected, handleSelect }: Props) => {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const [textSize, setTextSize] = useState<ITypography>(Typography.t4);

  const getCurrentStyles = () => {
    if (width <= DEVICE_SCREEN_MAXIMUM_SIZES.MOBILE) {
      setTextSize(Typography.t3);
    } else {
      setTextSize(Typography.t4);
    }
  };

  useEffect(() => {
    getCurrentStyles();
    // eslint-disable-next-line
  }, [width]);

  return (
    <View className={classes.style}>
      <ImageFrame isActive={isSelected} className={classes.thumbnail} onClick={handleSelect}>
        <Image
          className={classes.image}
          src={style.referral_image_url ? `/${DISCORD_ACTIVITY_URL_MAPPINGS.PA_CDN}/${style.referral_image_url}` : `/${DISCORD_ACTIVITY_URL_MAPPINGS.CMS}/${style.image.data.attributes.url}`}
          alt={style.title}
        />
        <View className={`${classes.selectedStyle} ${isSelected && classes.selectedStyleActive}`}>
          <View className={classes.overlay} />
          <View className={classes.overlayIcon}>
            <IconTickLarge fill={Texts.positive} />
          </View>
        </View>
      </ImageFrame>

      <Text size={textSize} className={classes.title}>
        {style.title}
      </Text>
    </View>
  );
};

export default Style;
