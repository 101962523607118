import { useSelector } from 'react-redux';
import { getDiscordSDK } from 'store/slices/main/selectors';
import { IReduxState } from 'types';
import getCurrentDateTime from 'utils/getCurrentDateAndTime';
import axios from "axios";
import {postLogs} from "../api/server/postLogs";

const generatedImageSelector = (state: IReduxState) => ({
  discordSDK: getDiscordSDK(state),
});

const useDownload = () => {
  const { discordSDK } = useSelector(generatedImageSelector);

  const download = (url: string) => {
    const dateAndTime = getCurrentDateTime();
    console.log({ dateAndTime, url });
    const name = `AI Avatar Discord ${dateAndTime}`;
    try {
      discordSDK?.commands.openExternalLink({
        url: `${process.env.REACT_APP_SERVER_URL}api/v2/media/download-page?url=${url}&filename=${name}`,
      });
      postLogs({"message": "expernal link triggered"});
    } catch (e) {
      postLogs({"download Error": JSON.stringify(e)}).then(() => {});
    }
  };

  const downloadAll = (urls: string[]) => {
    const dateAndTime = getCurrentDateTime();
    const name = `AI Avatar Discord ${dateAndTime}`;
    const encodedUrls = encodeURIComponent(JSON.stringify(urls));

    discordSDK?.commands.openExternalLink({
      url: `${process.env.REACT_APP_SERVER_URL}api/v2/media/download-page-all?imageUrls=${encodedUrls}&filename=${name}`,
    });
  };

  return { download, downloadAll };
};

export default useDownload;
