import { Texts, Shadows, Backgrounds } from '@picsart/ds-foundation/colors';
import { Timings } from '@picsart/ds-foundation/timings';
import { FontWeights } from '@picsart/ds-foundation/fonts';
import { Radius } from '@picsart/ds-foundation/radius';
import { Spacings } from '@picsart/ds-foundation/spacings';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    padding: `${Spacings.s16} ${Spacings.s24} `,
    transition: Timings.Fast,
    gap: Spacings.s24,
    alignItems: 'flex-start',
    borderRadius: Radius.r24,
    background: Backgrounds.positive,
  },
  root_active: {
    alignItems: 'flex-start',
    boxShadow: Shadows.tint1,
    background: Backgrounds.base,
  },
  index: {
    transition: Timings.Fast,
    color: Texts.tint1,
    fontWeight: FontWeights.SemiBold,
    display: 'flex',
    alignItems: 'center',
    height: Spacings.s40,
  },
  index_active: {
    color: Texts.base,
    height: 'auto',
  },
  textView: {
    flexDirection: 'column',
  },
  title: {
    width: '100%',
    transition: Timings.Fast,
    color: Texts.tint1,
    fontWeight: FontWeights.SemiBold,
    display: 'flex',
    alignItems: 'center',
    height: Spacings.s40,
  },
  title_active: { color: Texts.base },
  hidden_view: {
    transition: Timings.Fast,
    width: '100%',
    maxHeight: '0px',
    overflow: 'hidden',
    flexDirection: 'column',
    marginTop: Spacings.s0,
  },
  hidden_view_active: {
    maxHeight: '200px',
    marginTop: Spacings.s8,
  },

  description: {
    width: '100%',
    transition: Timings.Fast,
    fontWeight: FontWeights.Regular,
  },
  button: {
    marginTop: Spacings.s12,
    padding: `${Spacings.s12} ${Spacings.s16}`,
  },
});
